import { isDev } from "ui/lib/helpers";

const define = {
  id: "3a3ee0d8-e817-4c06-8620-351a27da67f1",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/define/og.png",
    title: `Ladder | Define by Coach Maia Henry`,
    description: `Tone your body with Yoga x Strength Training`,
  },
  teamName: "Team Define",
  coachNamesDesc: "COACH MAIA",
  hero: {
    title: "SCULPT YOUR BODY WITH STRENGTH X PILATES",
    description:
      "Get strong and build lean muscle with Coach Maia's unique blend of Pilates x Strength workouts.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/define/hero-desktop-5.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/define/hero-mobile-5.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/define/qr.png",
  },
  avatarImgixURL:
    "https://ladderteams.imgix.net/define/images/avatarImgixURL-926bceda-77ea-4704-a23a-e49bbc959df8",
  overview: {
    type: "Strength x Pilates",
    details: `It's time to define the hottest, happiest, strongest version of you, with Coach Maia's unique blend of Strength x Pilates workouts. Every week, certified pilates coach Maia Henry will program brand new workouts for you, to keep you motivated, excited and more consistent with your workout routine! It's time to transform your body, your mind and your confidence with Team Define, found only on Ladder.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "35-45 Min",
    similarTo: `Core Power, Power Vinyasa, HIIT`,
    suggestedEquipment: ["Dumbbells"],
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/define/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/define/ss/2.2.png",
    "https://ladderteams.imgix.net/website/teams/define/ss/3.1.png",
  ],
  quote: {
    showCopy: false,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/define/quote.jpg",
  },
  appSSStyle: [{}, {}, {}],
  waitlist: false,
  waitlistRedirectURL:
    "https://airtable.com/appWPYre9K689TYvY/shrlU04NS1e96XbUs",
  launchDate: "April 15th",
};

const vitality = {
  id: "c6fc4fab-75fd-40ba-a893-b5a6539a569c",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/vitality/og.png",
    title: `Ladder | Vitality by Coach Brian Pruett`,
    description: `Get your free 7-day plan with Coach Brian Pruett now!`,
  },
  teamName: "Team Vitality",
  coachNamesDesc: "COACH BRIAN",
  hero: {
    title: "BUILD YOUR DREAM PHYSIQUE WITH A DAILY PLAN",
    description:
      "Elevate your training and energize your life with Coach Brian Pruett’s minimal-equipment bodybuilding program on Ladder.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/vitality/hero-desktop.1.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/vitality/hero-mobile.3.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/vitality/qr.png",
  },
  avatarImgixURL:
    "https://ladderteams.imgix.net/website/teams/vitality/avatar.1.png",
  overview: {
    type: "FUNCTIONAL BODYBUILDING",
    details: `It’s time to build your dream physique and become 1% in every aspect of your life, through Coach Brian Pruett’s minimal-equipment functional bodybuilding program on Ladder. 

In just 30 to 40 minutes a day, you’ll train for an aesthetic physique using only a pair of dumbbells, a jump rope and the occasional kettlebell, with Coach Brian in your ear, guiding you through the entire workout. `,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "30-45 Min",
    similarTo: `Traditional Bodybuilding Programs`,
    suggestedEquipment: "Dumbbells",
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/vitality/ss/1.4.png",
    "https://ladderteams.imgix.net/website/teams/vitality/ss/2.1.png",
    "https://ladderteams.imgix.net/website/teams/vitality/ss/3.4.png",
  ],
  quote: {
    showCopy: false,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/vitality/quote.8.png",
  },
  appSSStyle: [{}, {}, {}],
  waitlist: false,
  waitlistRedirectURL: "https://form.typeform.com/to/gBrtaNpL",
  waitlistZapierWebhookURL:
    "https://hooks.zapier.com/hooks/catch/5389738/24lpjri/",
  launchDate: "August 25th",
};

const transform = {
  waitlist: isDev ? false : true,
  waitlistRedirectURL: "https://form.typeform.com/to/DJ4KhN0u",
  waitlistZapierWebhookURL:
    "https://hooks.zapier.com/hooks/catch/5389738/2b289b6/",
  launchDate: "July 7th",
  id: "8362e136-3d69-441f-bf5f-58975379cc6f",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/transform/og.png",
    title: `Transform by Coach Shelby Robins • @shelbyrobinss`,
    description: `Get your free trial now to build curves without the bulk!`,
  },
  teamName: "Team Transform",
  coachNamesDesc: "COACH SHELBY",
  hero: {
    title: "BUILD CURVES WITHOUT THE BULK",
    description:
      "Coach Shelby Robins combines lower body strength with cardio to help you build your dream body without bulk.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/transform/hero-desktop-2.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/transform/hero-mobile-3.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/transform/qr.png",
  },
  avatarImgixURL:
    "https://ladderteams.imgix.net/website/teams/transform/avatar-3.png",
  overview: {
    type: "Lower Body Strength x Cardio",
    details: `This program utilizes a unique split of heavy lower body days, paired with lighter, higher rep upper body days, to build curves in all the right places.

Each week you can expect exciting new progressive overload strength workouts paired with your favorite cardio, like stairmaster, elliptical or just a good ol’ fashioned Hot Girl Walk.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "45-60 Mins",
    similarTo: `Core Power, Power Vinyasa, HIIT`,
    suggestedEquipment: "Dumbbells, Barbell, Cardio Machines",
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/transform/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/transform/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/transform/ss/3.png",
  ],
  quote: {
    showCopy: false,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/transform/quote.png",
  },
  appSSStyle: [{}, {}, {}],
};

const ascend = {
  waitlist: isDev ? false : true,
  waitlistRedirectURL: "https://form.typeform.com/to/BWJ1OxjN",
  waitlistZapierWebhookURL:
    "https://hooks.zapier.com/hooks/catch/5389738/2b2k3ad/",
  launchDate: "July 7th",
  id: "700e6ad1-64fd-4255-9ae1-7b1300b6ecd9",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/ascend/og.png",
    title: `Ascend by Coach Aria Vince • @ariavincefitness`,
    description: `Get your free 7-day plan with Coach Aria Vince now!`,
  },
  teamName: "Team Ascend",
  coachNamesDesc: "COACH ARIA",
  hero: {
    title: "BUILD YOUR DREAM PHYSIQUE",
    description:
      "Certified strength, nutrition and female transformation Coach Aria Vince will help you sculpt and tone your body in all the right places.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/ascend/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/ascend/hero-mobile-2.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/ascend/qr2.png",
  },
  avatarImgixURL:
    "https://ladderteams.imgix.net/website/teams/ascend/avatar.png",
  overview: {
    type: "Lower Body Strength x Cardio",
    details: `Who said bodybuilding can't be feminine? This program utilizes a 3-2-1 bodybuilding split, with three days of lower body, two days of upper body and one core and mobility day to help you build your hourglass.

Women looking to build an hourglass physique will love Team Ascend. Coach Aria utilizes gym equipment like barbells, dumbbells and traditional strength machines to dial in muscle growth, with a specific focus on glutes, shoulders and back.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "45-60 Mins",
    similarTo: `Dumbbells, Barbells, Strength Machines`,
    suggestedEquipment: "Dumbbells, Barbell, Cardio Machines",
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/ascend/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/ascend/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/ascend/ss/3.png",
  ],
  quote: {
    showCopy: false,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/ascend/quote.png",
  },
  appSSStyle: [{}, {}, {}],
};

const align = {
  id: "d03b9ab4-7eca-4113-8af2-fbcbb402f7ee",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/align/og.png",
  },
  hero: {
    title: "TONE YOUR BODY WITH YOGA STRENGTH TRAINING",
    description:
      "Fall in love with Coach Sasha Hanway's unique combination of Power Vinyasa and dumbbell resistance training to become your strongest, most aligned self.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/align/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/align/hero-mobile_v0.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/align/qr.png",
  },
  overview: {
    type: "Yoga x Strength",
    details: `Team Align on the Ladder app is the perfect program if you're looking to combine strength workouts with the mindful practice of yoga. Equal parts Power Vinyasa flows and dumbbell strength training, Team Align borrows the best from both modalities: breathwork, flexibility and mobility from traditional yoga, as well as resistance training for lean muscle growth and body recomposition.

    Coach Sasha brings her expert-level experience as a certified personal trainer, holistic health coach, and registered yoga instructor to Team Align, guiding you every step of the way. In under 45 minutes a day, and with only a pair of dumbbells and a yoga block, you can align your physical, mental and emotional wellbeing through Team Align on Ladder.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "35-45 Min",
    similarTo: `Core Power, Power Vinyasa, HIIT`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/align/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/align/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/align/ss/3.png",
  ],
  quote: {
    showCopy: false,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/align/quote.jpg",
  },
  appSSStyle: [{}, {}, {}],
};

const elevate = {
  id: "b4250e1c-7840-412d-89a1-a0dde0fffe17",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/elevate/og.png",
  },
  hero: {
    title: "GET STRONGER & FASTER, WITH A COMPLETE PLAN",
    description:
      "Get fit fast as fitness expert and pro-trainer Elise Young coaches you through progressive workouts, programmed fresh each week.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/elevate/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/elevate/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/elevate/qr.png",
  },
  overview: {
    type: "Functional Strength Training",
    details: `Perfect for those who are busy but make time to workout; it’s efficient, effective and challenging. This complete strength training plan includes high intensity interval training, muscle group focused push and pull strength circuits and cardio.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "35-45 Min",
    similarTo: `Traditional Bodybuilding Programs`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/elevate/ss/1.gif",
    "https://ladderteams.imgix.net/website/teams/elevate/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/elevate/ss/3.gif",
  ],
  appSSStyle: [{}, { padding: 10 }, { scale: "1.03" }],
  quote: {
    showCopy: true,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/elevate/quote.jpg",
  },
};

const forged = {
  id: "50238840-4611-42a3-a5d0-88e9beaf9415",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/forged/og.png",
  },
  hero: {
    title: "BUILD YOUR DREAM PHYSIQUE WITH A DAILY PLAN",
    description:
      "Achieve aesthetic results and a lean, muscular body with this bodybuilding program from Coach Corey Perkins.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/forged/hero-desktop.jpeg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/forged/hero-mobile.jpeg",
    qrSrc: "https://ladderteams.imgix.net/website/teams/forged/qr.png",
  },
  overview: {
    type: "Bodybuilding",
    details: `Are you ready to eliminate wasted time in the gym and build your dream physique? Coach Corey Perkins will be your guide to building lean muscle while getting strong with his bodybuilding program, FORGED, available only on Ladder. This program utilizes hypertrophy and optimal exercises to maximize gym efficiency and achieve aesthetic results. \n
      Designed to be completed in a commercial gym, this program primarily utilizes a mix of traditional strength and cable machines, dumbbells and barbells. Each week you’ll receive 6-brand new workouts, with in-ear coaching from Coach Corey, as well as video demos and precise pacing for every movement. If you’ve been looking for a bodybuilding program from a world-class coach, new workouts every week and a plan that will actually deliver real-world results, Team Forged on Ladder is the program for you.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "45+ mins",
    similarTo: `Traditional Bodybuilding Programs`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/forged/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/forged/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/forged/ss/3.png",
  ],
  quote: {
    showCopy: false,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/forged/quote.png",
  },
};

const iconic = {
  id: "686c99db-d4c9-4a2e-8a0e-81c7d328f5e0",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/iconic/og.png",
  },
  hero: {
    title: "BUILD STRENGTH, GAIN CONFIDENCE AND BECOME ICONIC.",
    description:
      "Build confidence in the gym from the inside out with certified Performance Enhancement Specialist Sarah Ryan, through her weekly progressive training program.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/iconic/hero-desktop.jpg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/iconic/hero-mobile.jpg",
    qrSrc: "https://ladderteams.imgix.net/website/teams/iconic/qr.png",
  },
  overview: {
    type: "Bodybuilding / Strength & Conditioning",
    details: `Ready to walk into the gym with a newfound confidence?  Whether you’re new to strength training or just looking to reawaken your inner badass, this complete strength training plan from Coach Sarah Ryan combines her proven blend of traditional bodybuilding, power-enhancing plyometrics and glute-focused lower body workouts to help you build your strongest, most iconic self.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "45-60 minutes",
    similarTo: `Strength & Conditioning Classes, Personal Training`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/iconic/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/iconic/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/iconic/ss/3.png",
  ],
  quote: {
    showCopy: true,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/iconic/quote.png",
  },
};

const bodyAndBell = {
  id: "8886e69b-569a-4907-b9ad-b8b1cadf5adf",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/body-and-bell/og.png",
  },
  hero: {
    title: "GET STRONGER, FASTER, WITH A COMPLETE PLAN",
    description:
      "Achieve lean muscle with minimal equipment as fitness expert Lauren Kanski coaches you through progressive workouts, delivered fresh each week.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/body-and-bell/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/body-and-bell/hero-mobile.jpg",
    qrSrc: "https://ladderteams.imgix.net/website/teams/body-and-bell/qr.png",
  },
  overview: {
    type: "Full Body Strength Training",
    details: `Reinvigorate your fitness plan, develop new skills, and sweat alongside the best with this full body strength and fat loss program. Featuring kettlebell skills development, upper/lower body, core and booty strength workouts and yoga mobility.`,
    intensity: "Intermediate+",
    flames: 3,
    avgWorkoutDurDesc: "35-45 Min",
    similarTo: `Spice It Up`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/body-and-bell/ss/1.1.png",
    "https://ladderteams.imgix.net/website/teams/body-and-bell/ss/2.1.png",
    "https://ladderteams.imgix.net/website/teams/body-and-bell/ss/3.1.png",
  ],
  quote: {
    showCopy: true,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/body-and-bell/quote.jpg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/body-and-bell/quote-mobile.png",
  },
};

const boundless = {
  id: "102a7af2-63a5-4586-a7e1-d4110b802b30",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/boundless/og.png",
  },
  hero: {
    title: "OPTIMIZE YOUR WORKOUTS WITH A COMPLETE PLAN",
    description:
      "Introducing Boundless, Ben Greenfield’s exclusive workout program available only on the Ladder app.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/boundless/hero-desktop.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/boundless/qr.png",
  },
  overview: {
    type: "TABATA-STYLE WEIGHT TRAINING",
    details: `Biohack your workout routine with fitness guru Ben Greenfield’s Minimum Effective Dose methodology, which targets a cut-physique,  increased cardiovascular fitness and improved muscular endurance. Featuring 5 training days per week, an active recovery day, and a day dedicated to Ben’s best “biohacks.”`,
    intensity: "Intermediate+",
    flames: 3,
    avgWorkoutDurDesc: "35-45 Min",
    similarTo: `Spice It Up`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/boundless/ss/1.gif",
    "https://ladderteams.imgix.net/website/teams/boundless/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/boundless/ss/3.gif",
  ],
  quote: {
    showCopy: true,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/boundless/quote.jpg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/boundless/quote-mobile.png",
  },
};

const crewsControl = {
  id: "102a7af2-63a5-4586-a7e1-d4110b802b30",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/crews-control/og.png",
  },
  hero: {
    title: "STOP GUESSING, TRAIN WITH A PLAN",
    description:
      "Team Crews Control leverages high-intensity, functional fitness to build muscle and shed fat.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/crews-control/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/crews-control/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/crews-control/qr.png",
  },
  overview: {
    type: "Functional Weight Training",
    details: `Train like an athlete as you build lean muscle and decrease body fat with this functional movement and strength training program coached by Men’s Health Magazine’s Fittest Trainer. Workouts feature strength training performed at a high intensity level, skill work and functional exercises.`,
    intensity: "Intermediate+",
    flames: 3,
    avgWorkoutDurDesc: "35-45 Min",
    similarTo: `Train to Transform`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/crews-control/ss/1.gif",
    "https://ladderteams.imgix.net/website/teams/crews-control/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/crews-control/ss/3.gif",
  ],
  appSSStyle: [{}, { padding: 10 }, { scale: "1.03" }],
  quote: {
    showCopy: true,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/crews-control/quote.jpg",
  },
};

const empwr = {
  id: "d42fc5c0-f016-4f12-a1e0-5138c1f1a30a",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/empwr/og.png",
  },
  hero: {
    title: "SCULPT YOUR BODY WITH A COMPLETE PLAN",
    description:
      "Get strong with Courteney Fisher’s high-energy blend of Cardio, Pilates, and Strength workouts that build lean muscle tone and give you the confidence to feel your best.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/empwr/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/empwr/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/empwr/qr.png",
  },
  overview: {
    type: "Cardio Strength & Sculpt",
    details: `Pro Trainer and Fitness Model Courteney Fisher blends a unique mix of Full Body Strength, Pilates, HIIT Circuits, and Sweaty Sculpt Training into fun and intensive workouts that will transform your body, your mind, and your confidence.`,
    intensity: "Intermediate",
    flames: 3,
    avgWorkoutDurDesc: "45 Mins",
    similarTo: `Pilates, Yoga, Barry’s`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/empwr/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/empwr/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/empwr/ss/3.png",
  ],
  // appSSStyle: [{}, { padding: 10 }, { scale: "1.03" }],
  quote: {
    copy: "MY WORKOUTS ARE TOUGH, BUT YOU ARE TOUGHER.",
    showCopy: true,
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/empwr/quote-mobile.png",
    bgDesktop: "https://ladderteams.imgix.net/website/teams/empwr/quote.png",
  },
};

const evolve = {
  id: "b04d00ef-f50a-4a63-839e-2b7a5c8a5bd4",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/evolve/og.png",
  },
  hero: {
    title: "A DAILY BODYBUILDING PLAN TO GUARANTEE AESTHETIC RESULTS",
    description:
      "Sculpt lean muscle and the hourglass shape you’ve always dreamt of in under 45min a day!",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/evolve/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/evolve/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/evolve/qr.png",
  },
  overview: {
    type: "Bodybuilding",
    details: `Coach Samantha Lindauer will be your guide to achieving aesthetic results in this traditional bodybuilding program. With a doctorate in Physical Therapy and a background in Competitive Bodybuilding, Coach Samantha has developed this comprehensive hypertrophy program to build a shredded upper-body, defined glutes and an hourglass taper that will have you looking and feeling your best.

    Designed to be done in a commercial gym, this program primarily utilizes freeweights, in addition to traditional strength and cable machines. If you’re a non-competitive bodybuilder still looking for the same aesthetic results on a busy schedule, this program is for you!`,
    intensity: "Intermediate to Advanced",
    flames: 3,
    avgWorkoutDurDesc: "45 Mins",
    similarTo: `Traditional Bodybuilding Programs`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/evolve/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/evolve/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/evolve/ss/3.png",
  ],
  // appSSStyle: [{}, { padding: 10 }, { scale: "1.03" }],
  quote: {
    copy: "MY WORKOUTS ARE TOUGH, BUT YOU ARE TOUGHER.",
    showCopy: true,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/evolve/quote.png",
  },
};

const formation = {
  id: "d7ca7092-7f66-494a-b967-1c2c0cc9da44",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/formation/og.png",
  },
  hero: {
    title: "GET FIT AND GET IN FORMATION WITH DR. ROBIN B",
    description:
      "Sculpt lean muscle, tone your entire body and torch body fat with Coach Robin’s high-tempo combination circuits, in just 30 minutes a day!",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/formation/hero-desktop.jpeg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/formation/hero-mobile.jpg",
    qrSrc: "https://ladderteams.imgix.net/website/teams/formation/qr.png",
  },
  overview: {
    type: "High-Intensity Interval Training",
    details: `It’s time to turn up the tempo and transform your entire body with Team Formation on the Ladder app. Certified trainer Dr. Robin B knows what it’s like to juggle a healthy lifestyle with a hectic schedule.

    Through her unique blend of high-intensity combination movements, Coach Robin will have you in-and-out of the gym in under 35 minutes per day. Utilizing bodyweight and dumbbell circuit training, these workouts will burn fat and build muscle whether you work out at home or the gym. It’s time to get in Formation!`,
    intensity: "Intermediate",
    flames: 3,
    avgWorkoutDurDesc: "30-35 Mins",
    similarTo: `Barry's, Boot Camps, HIIT Classes`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/formation/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/formation/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/formation/ss/3.png",
  ],
  // appSSStyle: [{}, { padding: 10 }, { scale: "1.03" }],
  quote: {
    copy: "PUSH YOURSELF TO DO ALL THINGS WELL, IN AND OUT OF THE GYM.",
    showCopy: true,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/formation/quote.png",
  },
};

const limitless = {
  id: "18e10939-4f19-408a-aad8-1179c6ad91d6",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/limitless/og.png",
  },
  hero: {
    title: "BUILD SERIOUS STRENGTH WITH A COMPLETE PLAN",
    description:
      "Finally achieve the strength gains you’ve been dreaming about with expert Strength and Conditioning Coach Kelly Matthews, through her weekly progressive training program.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/limitless/hero-desktop.jpg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/limitless/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/limitless/qr.png",
  },
  overview: {
    type: "Advanced Strength & Conditioning",
    details: `Perfect for serious athletes and those who love strength training but hate planning their own workouts or who aren’t getting the results they’re working for. This complete strength training plan includes a proven blend of traditional strength training, high-intensity conditioning and steady state cardio to improve strength, power and overall athleticism.`,
    intensity: "Advanced",
    flames: 3,
    avgWorkoutDurDesc: "40-60 Mins",
    similarTo: `Advanced Strength Training`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/limitless/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/limitless/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/limitless/ss/3.png",
  ],
  // appSSStyle: [{}, { padding: 10 }, { scale: "1.03" }],
  quote: {
    copy: "PUSH YOURSELF TO DO ALL THINGS WELL, IN AND OUT OF THE GYM.",
    showCopy: true,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/limitless/quote.png",
  },
};

const maximus = {
  id: "1b5eb33b-281d-4824-9a7a-b47a7fa3f538",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/maximus/og.png",
  },
  hero: {
    title: "MAXIMIZE YOUR WORKOUTS, IN LESS THAN 30 MINUTES A DAY.",
    description:
      "Expert Coach and UFC Ultimate Fighter Contestant Bobby Maximus and Joe Cebula burn fat and build muscle with these no-frills, garage-style HIIT workouts.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/maximus/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/maximus/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/maximus/qr.png",
  },
  overview: {
    type: "Advanced Strength & Conditioning",
    details: `Perfect for serious athletes and those who love strength training but hate planning their own workouts or who aren’t getting the results they’re working for. This complete strength training plan includes a proven blend of traditional strength training, high-intensity conditioning and steady state cardio to improve strength, power and overall athleticism.`,
    intensity: "Advanced",
    flames: 3,
    avgWorkoutDurDesc: "40-60 Mins",
    similarTo: `Advanced Strength Training`,
    secondCoachImg:
      "https://ladderteams.imgix.net/website/teams/maximus/second-coach.png",
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/maximus/ss/1.gif",
    "https://ladderteams.imgix.net/website/teams/maximus/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/maximus/ss/3.gif",
  ],
  appSSStyle: [{}, { padding: 20 }, { padding: 5 }],
  quote: {
    copy: "Bet on yourself and put in the work. Every. Damn. Day.",
    showCopy: true,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/maximus/quote.png",
  },
};

const movewell = {
  id: "433adeaf-734a-4942-af48-a59e56970f89",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/movewell/og.png",
  },
  hero: {
    title: "TONE YOUR BODY WITH A COMPLETE PLAN",
    description:
      "Nicole Winter’s high-energy, minimal equipment strength workouts are perfect for anyone looking to build strength and muscle tone in just 30 minutes a day.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/movewell/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/movewell/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/movewell/qr.png",
  },
  overview: {
    type: "Essential Strength & Cardio",
    details: `Looking for a fun and challenging workout routine from an expert coach? In only 30 minutes a day, Coach Nicole delivers minimal-equipment strength workouts through cardio and dumbbell circuit training that will leave you feeling strong and looking amazing.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "30 Mins",
    similarTo: `Cardio bootcamp`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/movewell/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/movewell/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/movewell/ss/3.png",
  ],
  // appSSStyle: [{}, { padding: 20 }, { padding: 5 }],
  quote: {
    copy: "LET’S CELEBRATE WHAT OUR BODIES CAN DO!",
    showCopy: true,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/movewell/quote.png",
  },
};

const projectAlpha = {
  id: "6b5a92d1-89e1-474a-8255-2d5a4a856374",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/project-alpha/og.png",
  },
  hero: {
    title: "STOP GUESSING, TRAIN WITH A PLAN",
    description:
      "Team Project Alpha’s circuit training and sprint workouts will unlock your inner-Ironman.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/project-alpha/hero-desktop.png",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/project-alpha/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/project-alpha/qr.png",
  },
  overview: {
    type: "Endurance & Mindset",
    details: `Get ready for some serious hustle with this speed, power, endurance and strength training program designed to improve your mindset and push you to new limits. Featuring next level circuit-style strength training and outdoor sprint workouts designed for at home or the gym.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "45-60 Mins",
    similarTo: `Push Your Limits`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/project-alpha/ss/1.gif",
    "https://ladderteams.imgix.net/website/teams/project-alpha/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/project-alpha/ss/3.gif",
  ],
  appSSStyle: [{ padding: 5 }, {}, {}],
  quote: {
    copy: "See what you’re made of today.",
    showCopy: true,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/project-alpha/quote.jpg",
  },
};

const resilient = {
  id: "c879f00b-6741-4cd1-8a10-802bfc514162",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/resilient/og.png",
  },
  hero: {
    title: "STRENGTH AND CONDITIONING FOR EVERYDAY ATHLETICISM",
    description:
      "Team Resilient is an innovative strength-training program led by pro skateboarder and expert coach Neen Williams that delivers real-world results using a proven blend of explosive lifting and conditioning, designed for longevity and sustainability.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/resilient/hero-desktop.jpg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/resilient/hero-mobile.jpg",
    qrSrc: "https://ladderteams.imgix.net/website/teams/resilient/qr.png",
  },
  overview: {
    type: "Explosive Lifting and Conditioning",
    details: `Team Resilient a strength training program that blends explosive lifts and conditioning using primarily a barbell and kettlebells. Every week, expert coach Neen Williams delivers 6 brand-new workouts designed to challenge you in less than an hour a day, so you can look good, feel great and train with long-term sustainability in mind, without ever having to plan your own workout.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "45-60 Mins",
    similarTo: `Push Your Limits`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/resilient/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/resilient/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/resilient/ss/3.png",
  ],
  appSSStyle: [{ padding: 5 }, {}, {}],
  quote: {
    copy: "We train to do the things we love for as long as possible.",
    showCopy: true,
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/resilient/quote.jpg",
    bgMobile:
      "https://ladderteams.imgix.net/website/teams/resilient/quote-mobile.jpg",
  },
};

const rx = {
  id: "e6a9e1b8-dad8-4372-9f6d-8079644a19fa",
  og: {
    src: "https://ladderteams.imgix.net/website/teams/rx/og.png",
  },
  hero: {
    title: "A CROSSFIT-STYLE TRAINING PROGRAM, ON YOUR TERMS.",
    description:
      "Team RX is a challenging functional-strength program on the Ladder app that combines AMRAPs, EMOMs and interval strength training with guidance from your expert coach, Emily Rethwill.",
    bgDesktop:
      "https://ladderteams.imgix.net/website/teams/rx/hero-desktop.jpg",
    bgMobile: "https://ladderteams.imgix.net/website/teams/rx/hero-mobile.png",
    qrSrc: "https://ladderteams.imgix.net/website/teams/rx/qr.png",
  },
  overview: {
    type: "Crossfit-Style Functional Strength",
    details: `Looking for crossfit-style programming, but in your gym and on your schedule? Crossfit Athlete and All-American Track Champion Emily Rethwill guides you through a combination of AMRAPs, EMOMs and functional strength training using primarily a barbell and dumbbells. Each workout ranges from 45 to 60 minutes and includes video demos, in-ear coaching from Emily, as well as precise timing for every movement. Whether you're in a home gym with limited access to equipment, or in a commercial gym with additional machines like rowers, assault bikes, or ski-ergs, this program delivers efficient workouts and amazing results.`,
    intensity: "Intermediate",
    flames: 2,
    avgWorkoutDurDesc: "45-60 Mins",
    similarTo: `Like Crossfit, F45, Bodybuilding`,
  },
  appSS: [
    "https://ladderteams.imgix.net/website/teams/rx/ss/1.png",
    "https://ladderteams.imgix.net/website/teams/rx/ss/2.png",
    "https://ladderteams.imgix.net/website/teams/rx/ss/3.png",
  ],
  // appSSStyle: [{ padding: 5 }, {}, {}],
  quote: {
    copy: "FIND YOUR FLOW. UNLOCK YOUR POTENTIAL.",
    showCopy: true,
    bgDesktop: "https://ladderteams.imgix.net/website/teams/rx/quote.jpg",
  },
};

export const teamData = {
  ["c6fc4fab-75fd-40ba-a893-b5a6539a569c"]: vitality,
  ["3a3ee0d8-e817-4c06-8620-351a27da67f1"]: define,
  vitality,
  transform,
  ascend,
  define,
  align,
  elevate,
  forged,
  iconic,
  "body-and-bell": bodyAndBell,
  boundless,
  "crews-control": crewsControl,
  empwr,
  evolve,
  formation,
  limitless,
  maximus,
  movewell,
  "project-alpha": projectAlpha,
  resilient,
  rx,
};
