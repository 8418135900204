"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useMemo } from "react";
import { captureEvent } from "ui/lib/captureEvent";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { useModals } from "./modals/container";
const capureEvents = team => {
  captureEvent({
    action: "CTA Clicked",
    category: "coach_page",
    label: "CTA Clicked",
    value: "CTA Clicked",
    teamID: team.id,
    teamName: team.name
  });
  captureEvent({
    skipMapping: true,
    action: "APPSTORE_REDIRECT",
    category: "coach_page",
    label: "Redirected to the app store",
    value: "User Clicked",
    teamID: team.id,
    teamName: team.name
  });
};
const getDeeplink = (teamID: string, teamName: string) => {
  if (typeof window === "undefined") return new URL("https://joinladder.com");
  const tiktokHelperImage = "https://ladderteams.imgix.net/website/quiz/firebaseurlpreview_tiktok_402x180.jpg";
  const baseUrl = new URL("https://ladder.page.link?ibi=com.ladder.bootcamp&isi=1502936453&ius=ladderteams");
  const baseDeepLink = new URL(`https://teams.joinladder.com?launchProgramID=${teamID}`);
  function getUrlWithUtms(params: URLSearchParams, url: URL) {
    const utmKeyDefaults = {
      utm_source: "organic",
      utm_medium: "coach-acquisition",
      utm_campaign: "coach-page",
      utm_content: teamName
    };
    const utmKeys = ["utm_source", "utm_medium", "utm_term", "utm_campaign", "utm_content", "promoCode"];
    utmKeys.map(key => {
      const value = params.get(key) || utmKeyDefaults[key];
      if (!!value) {
        url.searchParams.set(key, value);
      }
    });
    return url;
  }
  const params = new URLSearchParams(window.location.search);
  const link = getUrlWithUtms(params, baseDeepLink);
  const deepLink = new URL(baseUrl);
  if (params.get("utm_source") === "tiktok") {
    deepLink.searchParams.set("si", tiktokHelperImage);
  } else {
    deepLink.searchParams.set("si", "https://ladderteams.imgix.net/website/quiz/firebaseurlpreview_402x180.jpg");
  }
  deepLink.searchParams.set("link", link.toString());
  return deepLink;
};
const TeamNavCTAButton = ({
  team
}) => {
  const deeplink = useMemo(() => team && getDeeplink(team.id, team.name), [team]);
  const handleClick = () => {
    capureEvents(team);
  };
  const Component = ({
    handleClick
  }) => <a onClick={handleClick} href={deeplink.toString()} className="items-center justify-center transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 px-4 rounded-full py-2 flex text-sm font-bold" data-sentry-component="Component" data-sentry-source-file="team-cta-button.tsx">
      <span>TRY FOR FREE</span>
    </a>;
  return <>
      <div className="flex lg:hidden">
        <Component handleClick={handleClick} data-sentry-element="Component" data-sentry-source-file="team-cta-button.tsx" />
      </div>
      <div className="hidden lg:flex">
        <Component handleClick={e => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }} data-sentry-element="Component" data-sentry-source-file="team-cta-button.tsx" />
      </div>
    </>;
};
const TeamCTAButtonShell = props => <a className="items-center justify-center transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 px-4 rounded-full w-full py-4 flex text-lg mb-4 font-bold" {...props} data-sentry-component="TeamCTAButtonShell" data-sentry-source-file="team-cta-button.tsx">
    <span>{props.text ?? "START FREE TRIAL"}</span>
  </a>;
const TeamCTAButton = ({
  team,
  ...props
}) => {
  const isMobile = useIsMobile();
  const {
    toggleShowQRModal
  } = useModals();
  const deeplink = useMemo(() => team && getDeeplink(team.id, team.name), [team]);
  const handleClick = e => {
    capureEvents(team);
    if (!isMobile) {
      e.preventDefault();
      toggleShowQRModal();
    }
  };
  return <TeamCTAButtonShell onClick={handleClick} href={deeplink.toString()} {...props} data-sentry-element="TeamCTAButtonShell" data-sentry-component="TeamCTAButton" data-sentry-source-file="team-cta-button.tsx" />;
};
function V0NoticePlaceholder({
  ...props
}) {
  return <nav className="w-full px-4 py-3 text-xs text-center border-b bg-background center md:text-sm lg:px-12 lg:text-base" data-sentry-component="V0NoticePlaceholder" data-sentry-source-file="team-cta-button.tsx">
      <a className="flex justify-between gap-1 font-bold center w-full container max-w-lg mx-auto" {...props}>
        <span className="text-white">NEW! 6-week MAX Strength Series</span>{" "}
        <div className="flex items-center gap-1 text-primary">
          <span className="underline">Try for free</span>
          <ArrowRightIcon className="w-4 h-4" data-sentry-element="ArrowRightIcon" data-sentry-source-file="team-cta-button.tsx" />
        </div>
      </a>
    </nav>;
}
function V0Notice({
  team
}) {
  const deeplink = useMemo(() => team && getDeeplink(team.id, team.name), [team]);
  const handleClick = () => {
    capureEvents(team);
  };
  return <V0NoticePlaceholder onClick={handleClick} href={deeplink.toString()} data-sentry-element="V0NoticePlaceholder" data-sentry-component="V0Notice" data-sentry-source-file="team-cta-button.tsx" />;
}
export { TeamCTAButton, TeamCTAButtonShell, TeamNavCTAButton, V0Notice, V0NoticePlaceholder };